

/*Mobile Device*/
@media only screen and (max-width: 600px) {
    .resultBox{
        width: 100%;
        height: 50vh;
        overflow-y: auto;
    }


    #filterContainer {
        display: flex;
        justify-content: center; /* Center the entire section horizontally */
        flex-direction: column-reverse;
    }

    #filter {
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center the entire section horizontally */
    }

    /* this is the filter button div itself */
    #filters {
        scale: 0.8;
        max-width: 350px; /* Example value, adjust as needed */
        grid-row: 1 / span 2; /* Occupies both rows */
      }

    #raritySelector, #attributeSelector  {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
        justify-items: center; /* Center buttons within each grid cell */
        gap: 5px; /* Add some space between buttons */
      }
}

/*Desktop Device*/
@media only screen and (min-width: 1000px) {
    .resultBox {
        width: 50%;
        float: left;
        height: 83.5vh;
        overflow-y: auto;
    }

    #filter {
        width: 50%;
        float: left;
    }


}
        
    




