.images {
    width: fit-content;
    display: block;
    margin: 0 auto;
}

#UnitImage {
    width: fit-content;
    margin: 0 auto;
   
}
  

.toggle {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 10px;
}

