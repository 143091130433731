.container {
    height: 75vh; /* Set height to fill up the entire viewport */
    display: flex;
    
}

.comic_list {
    /* border: 1px solid black; */
    width: 50%;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Set three equal-width columns */
    overflow-y: auto;
}

.comic {
    background: rgb(245, 245, 245);
    width: fit-content;
    border: 1px solid black;
    margin: 5px;
    border-radius: 5px;
    display: grid;
    justify-content: center;
    
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;

}

.comic_viewer {
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

#comic_window {
    
}

.viewer {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
}

.viewer_container {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}