@media (max-width: 700px) {
    #eos {
        justify-content: center;
        padding: 10px;
        white-space: nowrap;
        /* right: 0; */
        margin-left: 40%;
        width: fit-content;
        top: 0;
        /* right: 0; */
        left: 0;
        position: fixed;
        background-color: black;
        visible: hidden;

    }

    .Clock-days {
        padding-left: 15px;
    
    }

    .title {
        height: 30px;
        width: 100px;
        white-space: nowrap;
        font-size: 10px;
    }

}

@media screen and (min-width: 1000px) {
    
    #eos {
        align-items: center;
        justify-content: center;
        margin: 5px;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 10px;
        margin-left: 70%;
        width: fit-content;
        top: 0;
        right: 0;
        position: fixed;
        background-color: rgb(33, 78, 228);
        
    }

    List {
        width: 100px;
    }
}