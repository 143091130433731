/* styles.css */

/* General styles */
@media (min-width: 768px){
  
.mp3-player {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
  
  /* Grid styles */
  #grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 10px;
  }
  
  /* Box styles */
  .box {
    background-color: #f2f2f2;
    border-radius: 5px;
    border: 2px solid #ccc;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .box h3 {
    margin-bottom: 10px;
  }
  
  /* List styles */
  .song-list {
    list-style: none;
    padding: 0;
    text-align: left;
    cursor: pointer;
  }
  
  .song-list li {
    margin-bottom: 5px;
  }
  
  /* Audio player styles */
  .audio-player {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .audio-player img {
    width: 50px;
    height: 50px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  /* Favorite songs styles */
  .favorite {
    cursor: pointer;
  }

  /* style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', padding: '10px' }} */
  .song-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 10px;

  }

  /* style={{ backgroundColor: '#f2f2f2', borderRadius: '5px', border: '2px solid #ccc', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} */
}
.songList {
  background-color: '#f2f2f2';
  border-radius: '5px';
  border: '2px solid #ccc';
  text-align: 'center';
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  justify-content: 'center';
}
/* Add more specific styles as needed */


@media (max-width: 768px) {
    .mp3-player {
      display: grid;
      grid-template-columns: 1fr;
      
    }

    .song-container {
      /* gap: 20px; */
    }
    
  }