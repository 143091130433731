html,body{
  height:100%;
}

.divider {
    border: 1px solid black;

}

.main_container {
    /* border: 1px solid black;     */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;   
    height: 100%;
    
}

.menu_container {
    margin: auto;
    
}

.grid-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .grid-item {
    flex: 0 0 33.33%;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 5px;
  }
  
  .grid-item--row-0 {
    /* Add specific styles for items on row 0, if needed */
  }
  
  .grid-item--row-1 {
    /* Add specific styles for items on row 1, if needed */
  }
  
  .grid-item--col-0 {
    /* Add specific styles for items on column 0, if needed */
  }
  
  .grid-item--col-1 {
    /* Add specific styles for items on column 1, if needed */
  }
  
  .grid-item--col-2 {
    /* Add specific styles for items on column 2, if needed */
  }
  