/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: none;
    scrollbar-color: #dfdddd #595959;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: #595959;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #dfdddd;
    border-radius: 7px;
    border: 0px none #000000;
  }